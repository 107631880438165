<template>
  <div>

    <!-- Подтверждение удаления  -->
    <Modal
        :showing="showModal"
        @close="showModal = false"
        :showClose="true"
        :backgroundClose="true"
    >
      <p class="font-bold">Удалить хотелку?</p>

      <div class="flex justify-end">
        <span class="button shrink mr-2" @click="deleteGift">Да</span>
        <span class="button button-disabled shrink" @click="showModal = false">Отмена</span>
      </div>
    </Modal>

    <div class="wrapper flash-wrapper wrapper-fail" v-if="isGiftAdded === false">
      <p>{{ errors.adding }}</p>
    </div>

    <div class="wrapper flash-wrapper wrapper-success" v-if="isGiftAdded === true">
      <p>Подарок успешно добавлен!</p>
    </div>

    <div class="wrapper">
      <div v-if="gifts.length < maxGifts">
        <div class="form-item">
          <label for="gift" class="font-medium font-sm mb-1">Введите название подарка</label>
          <input
              type="text" id="gift" placeholder="Я хочу себе..." :maxlength="maxGiftLength" :minlength="minGiftLength"
              :value="gift" @input="gift = $event.target.value"
              v-on:keyup.ctrl.enter="addGift"
              class="appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>

        <button class="button w-full" :class="{'button-disabled' : isAddingBlocked}" @click="addGift"
                v-if="!isAddingLoading">Добавить
        </button>
        <Spinner class="mt-1" v-if="isAddingLoading"/>
      </div>

      <div v-if="gifts.length >= maxGifts">
        <p class="text-sm text-center">Вы добавили максимальное количество подарков в свой список желаний.</p>
      </div>
    </div>

    <div class="wrapper">
      <h3 class="text-md font-medium mb-3">Мои хотелки</h3>
      <ul class="gift-list" v-if="gifts.length > 0">
        <li class="gift-list__item" v-for="(item, i) in gifts" :key="item.id">
          <div class="w-full flex items-center justify-between overflow-hidden">

            <div class="w-10 flex justify-center mr-3">{{ i + 1 }}.</div>
            <div class="w-full flex font-medium overflow-x-hidden break-words">{{ item.title }}</div>
            <div class="w-10 flex justify-center ml-3" @click="showDeleteModal(item)">
              <Cancel classes="w-6 cursor-pointer"/>
            </div>

          </div>
        </li>
      </ul>
      <p v-else class="text-center text-sm">Список хотелок пуст.</p>
    </div>
  </div>
</template>

<script>
import {store} from "../store";
import {decrementHistoryDepth, setPanelHeader, toTopPage} from "../lib/utils";
import {createGift, deleteGift, getGiftsByUserId, maxGifts} from "../services/gift";

import Spinner from "../components/Spinner/Spinner";
import Cancel from "../components/Svg/28/Cancel";
import Modal from "../components/Modal/Modal";

export default {
  name: "AddGift",
  components: {Modal, Cancel, Spinner},
  data: function () {
    return {
      gift: "",
      gifts: [],
      deletedGift: "",

      showModal: false,

      maxGifts: 7,
      minGiftLength: 3,
      maxGiftLength: 100,

      isGiftAdded: null,
      isGiftDeleted: null,

      isAddingBlocked: true,
      isAddingLoading: false,
      isDeletingLoading: false,
      isGiftListLoading: false,

      errors: {
        adding: "",
        lastCall: null,
        lastCallTimer: null,
        popupIsCalling: false,
      }
    }
  },
  mounted() {
    decrementHistoryDepth();
    setPanelHeader("Добавить", true);
    toTopPage();

    this.maxGifts = maxGifts;

    // Загрузка списка подарков из кеша или сервера
    this.loadGifts();
  },
  destroyed() {
    this.showModal = false;
  },
  methods: {
    addGift() {
      // Кнопка добавления заблокирована
      if (this.isAddingBlocked) {
        return;
      }

      // Размер строки без учёта пробельных символов
      const giftLength = this.gift.replace(/ /g, '').length;

      if (giftLength === 0) {
        this.errorAdding("Нельзя добавить подарок без названия.");
        return;
      }

      const trimmedGift = this.gift.trim();
      for (let i = 0; i < this.gifts.length; i++) {
        const item = this.gifts[i];
        if (item.title.indexOf(trimmedGift) !== -1 && item.title.length === giftLength) {
          this.errorAdding("Такой подарок уже есть в списке.");
          return;
        }
      }

      // отклоняем слишком длинные и короткие строки
      if (giftLength < this.minGiftLength) {
        this.errorAdding(`Название должно быть длиннее ${this.minGiftLength - 1} символов.`);
        return;
      }

      if (giftLength > this.maxGiftLength) {
        this.errorAdding(`Название должно быть короче ${this.maxGiftLength} символов.`);
        return;
      }

      this.isAddingLoading = true;
      createGift(trimmedGift).then(() => {
        // добавление в локальный список подарков
        this.gifts.push({
          "title": trimmedGift,
        });

        // сохранение обновлённого списка в сторе
        store.commit("gift/setMyGiftList", this.gifts);

        this.gift = "";
        this.isGiftAdded = true;
        this.isAddingBlocked = true; // блокируем кнопку добавления

        setTimeout(() => {
          this.isGiftAdded = null;
          this.errors.adding = "";
        }, 3000);

      }).catch(err => {
        const E_TITLE_EMPTY = "E_TITLE_EMPTY";
        const E_USER_ID_EMPTY = "E_USER_ID_EMPTY";
        const E_USER_MAX_GIFTS_LIMIT = "E_USER_MAX_GIFTS_LIMIT";
        const E_GIFT_ALREADY_EXIST = "E_GIFT_ALREADY_EXIST";
        const E_GIFT_TOO_LONG = "E_GIFT_TOO_LONG";
        const E_GIFT_TOO_SHORT = "E_GIFT_TOO_SHORT";

        const serverError = err.response.data.response.error;

        if (serverError.indexOf(E_TITLE_EMPTY) > -1) {
          this.errorAdding("Название подарка должно быть заполнено.");
        } else if (serverError.indexOf(E_USER_ID_EMPTY) > -1) {
          this.errorAdding("При добавлении подарка произошла ошибка. Попробуйте позже.");
        } else if (serverError.indexOf(E_USER_MAX_GIFTS_LIMIT) > -1) {
          this.errorAdding("Лимит на количество подарков в списке превышен.");
        } else if (serverError.indexOf(E_GIFT_ALREADY_EXIST) > -1) {
          this.errorAdding("Такой подарок уже есть в списке.");
        } else if (serverError.indexOf(E_GIFT_TOO_LONG) > -1) {
          this.errorAdding("Название подарка слишком длинное (более 100 символов)");
        } else if (serverError.indexOf(E_GIFT_TOO_SHORT) > -1) {
          this.errorAdding("Название подарка слишком короткое (менее 3 символов)");
        } else {
          this.errorAdding("При добавлении подарка произошла ошибка. Попробуйте позже.");
        }

      }).finally(() => {
        this.isAddingLoading = false;
      })
    },

    showDeleteModal(gift){
      this.showModal = true;
      this.deletedGift = gift;
    },

    deleteGift() {
      if (this.isDeletingLoading === true) {
        return;
      }

      this.showModal = false;
      const gift = this.deletedGift;

      deleteGift(gift.title).then(() => {
        this.isDeletingLoading = true;
        // удаление из локального списка подарков
        let id = -1;
        this.gifts.forEach((item, i) => {
          if (item["title"] === gift.title) {
            id = i;
          }
        });
        this.gifts.splice(id, 1);
        // сохранение обновлённого списка в сторе
        store.commit("gift/setMyGiftList", this.gifts);

      }).catch(err => {
        console.log(err);
      }).finally(() => {
        this.isDeletingLoading = false;
      });

      this.deletedGift = null;
    },

    loadGifts() {
      this.gifts = [];

      // ищем подарки в кеше
      const storedGifts = store.state.gift.myGifts;
      if (storedGifts.length > 0) {
        this.gifts = storedGifts;
        return;
      }

      // если в кеше нет, то запрашиваем с сервера
      this.isGiftListLoading = true;
      getGiftsByUserId().then(res => {
        if (res.data.response.items !== null) {
          this.gifts = res.data.response.items;
        }

        // сохраняем список в сторе
        store.commit("gift/setMyGiftList", this.gifts);
      }).catch(err => {
        console.log(err);
      }).finally(() => {
        this.isGiftListLoading = false;
      })
    },

    errorAdding(error) {
      this.isGiftAdded = false;
      this.errors.adding = error;

      if (!this.errors.popupIsCalling) {
        this.errors.popupIsCalling = true;
        setTimeout(() => {
          this.isGiftAdded = null;
          this.errors.adding = "";
          this.errors.popupIsCalling = false;
        }, 3000);
      }

    },

    validateInput() {
      // Размер строки без учёта пробельных символов
      const strLength = this.gift.replace(/ /g, '').length;

      if (this.gift !== null && strLength > 0) {
        // Чистка от ненужных символов
        // this.gift = this.gift.replace(/[^ёЁа-яА-Яa-zA-Z0-9 - -":'?!@#$%^&*()+,.]/g, "");
        // Чистка от лишних пробелов
        this.gift = this.gift.replace(/ +/g, ' ');

        // console.log(strLength, strLength < this.minGiftLength, strLength > this.maxGiftLength);

        // отколняем слишком длинные и короткие строки
        if (strLength < this.minGiftLength) {
          this.isAddingBlocked = true;
          return false;
        }

        // обрезаем длинную строку
        if (strLength > this.maxGiftLength) {
          this.gift = this.gift.substring(0, this.maxGiftLength);
        }

        // Снятие блокировки с кнопки
        this.isAddingBlocked = false;
      }
    },
  },
  watch: {
    gift() {
      this.validateInput();
    },
  },
  // TODO отключить восстановление позиции скролла
  // beforeRouteLeave(to, from, next) {
  //   if (this.showModal === true) {
  //     this.showModal = false;
  //     next(false);
  //   } else {
  //     next();
  //   }
  // },
}
</script>

<style lang="scss" scoped>
.form-item {
  flex-direction: column;

  & label {
    display: flex;
  }

  & input[type="text"], & select {
    border: 2px solid #eee;
    background-color: white;
    -moz-appearance: textfield;
  }

  & input[type="text"]:active, & input[type="text"]:focus, & select:focus {
    border-color: #d4d4d4;
    outline: none;
  }
}

.gift-list__item {
  @apply rounded-md py-2 list-none flex items-center overflow-hidden;

  background-color: var(--white-wrapper-background);
  color: var(--default-text-color);
}

.gift-list__item:not(:first-child) {
  @apply mt-2;
}

.flash-wrapper {
  @apply text-center font-medium;
  color: #212529;
}

.wrapper-fail {
  @apply bg-red-200 text-center font-medium;
}

.wrapper-success {
  @apply bg-green-200 text-center font-medium;
}

.button {
  @apply transition-all;
  color: white;
  background-color: var(--vk_btn_blue);

  &:hover {
    background-color: var(--vk_palette_blue_bright);
  }

  &:active {
    background-color: var(--vk_btn_blue);
  }

  @media (hover: none) {
    &:hover {
      background-color: var(--vk_btn_blue);
    }
  }
}

.button-disabled {
  color: #212529;
  background-color: var(--vk_secondary_button);

  &:hover {
    background-color: var(--vk_secondary_button);
  }

  &:active {
    background-color: var(--vk_secondary_button);
  }

  @media (hover: none) {
    &:hover {
      background-color: var(--vk_secondary_button);
    }
  }
}
</style>