<template>
  <svg :style="{ color: color }" :class="[ classes ]" height="28px" version="1.1" viewBox="0 0 28 28" width="28px"
       xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g fill="none" fill-rule="evenodd" id="Page-1" stroke="none" stroke-width="1">
      <g id="cancel_outline_28">
        <rect height="28" width="28" x="0" y="0"></rect>
        <path d="M6.29289322,6.29289322 C6.68341751,5.90236893 7.31658249,5.90236893 7.70710678,6.29289322 L7.70710678,6.29289322 L14,12.585 L20.2928932,6.29289322 C20.6533772,5.93240926 21.2206082,5.90467972 21.6128994,6.20970461 L21.7071068,6.29289322 C22.0976311,6.68341751 22.0976311,7.31658249 21.7071068,7.70710678 L21.7071068,7.70710678 L15.415,14 L21.7071068,20.2928932 C22.0675907,20.6533772 22.0953203,21.2206082 21.7902954,21.6128994 L21.7071068,21.7071068 C21.3165825,22.0976311 20.6834175,22.0976311 20.2928932,21.7071068 L20.2928932,21.7071068 L14,15.415 L7.70710678,21.7071068 C7.34662282,22.0675907 6.77939176,22.0953203 6.38710056,21.7902954 L6.29289322,21.7071068 C5.90236893,21.3165825 5.90236893,20.6834175 6.29289322,20.2928932 L6.29289322,20.2928932 L12.585,14 L6.29289322,7.70710678 C5.93240926,7.34662282 5.90467972,6.77939176 6.20970461,6.38710056 Z"
              fill="currentColor" fill-rule="nonzero" id="↳-Icon-Color"></path>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "Cancel",
  props: {
    color: {
      type: String
    },
    classes: {
      type: String
    }
  }
}
</script>

<style scoped>

</style>