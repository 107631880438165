import VueRouter from 'vue-router'

import Home from "./panels/Home.vue";
import AddGift from "./panels/AddGift.vue";
import MyFriends from "./panels/MyFriends";
import ShowGifts from "./panels/ShowGifts";

export const router = new VueRouter({
    mode: "hash",
    routes: [
        {path: '/', component: Home},

        // Работа с подарками
        {path: '/gifts/', component: Home}, // Переадресация на главную

        {path: '/gifts/add', component: AddGift}, // Добавление подарка
        {path: '/gifts/:id', component: ShowGifts}, // Просмотр списка подарков моего друга

        // Друзья
        {path: '/my-friends', component: MyFriends}, // Список друзей пользователя
    ],
});
