<template>
  <div class="wrapper">
    <div class="flex items-center justify-between">
      <div class="w-full flex flex-col">
        <p class="font-medium mb-1">Челлендж в разгаре 🎉</p>
        <p class="text-sm">Уже совсем скоро настанет Новый год, и мы начнём веселиться и обмениваться подарочками!</p>
      </div>
    </div>

    <div class="flex items-center flex-col">
      <div class="w-full flex items-center justify-center h-24">
        <div class="flex" v-if="! isLoading">

          <div class="text-4xl text-center font-bold" v-if="isFirstWeek">
            С Новым годом!
          </div>

          <div class="flex" v-if="!isFirstWeek">
            <div class="new_year__item">
              <div class="new_year__item-time">{{ days }}</div>
              <div class="new_year__item-title">
                {{ getEnding(days, ['день', 'дня', 'дней']) }}
              </div>
            </div>
            <div class="new_year__item">
              <div class="new_year__item-time">{{ hours }}</div>
              <div class="new_year__item-title">
                {{ getEnding(hours, ['час', 'часа', 'часов']) }}
              </div>
            </div>
            <div class="new_year__item">
              <div class="new_year__item-time">{{ minutes }}</div>
              <div class="new_year__item-title">
                {{ getEnding(minutes, ['минута', 'минуты', 'минут']) }}
              </div>
            </div>
            <div class="new_year__item">
              <div class="new_year__item-time">{{ seconds }}</div>
              <div class="new_year__item-title">
                {{ getEnding(seconds, ['секунда', 'секунды', 'секунд']) }}
              </div>
            </div>
          </div>
        </div>
        <Spinner v-if="isLoading"/>
      </div>
    </div>

  </div>
</template>

<script>
import {getNumEnding} from "../../lib/utils";
import Spinner from "../Spinner/Spinner";

export default {
  name: "HappyNewYear",
  components: {Spinner},
  data() {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,

      isLoading: true,
      isStoriesLoading: false,
      isStoriesSend: false,
      isFirstWeek: false,
    };
  },
  created() {
    setInterval(() => {
      this.countDown();
    }, 1000);
  },
  methods: {
    countDown() {
      let now = new Date();
      let nearestNewYear = 'January 1 ' + (now.getFullYear() + 1) + " 00:00:00";

      this.isLoading = true;

      let t = Date.parse(nearestNewYear) - now;
      let seconds = Math.floor((t / 1000) % 60);
      let minutes = Math.floor((t / 1000 / 60) % 60);
      let hours = Math.floor((t / (1000 * 60 * 60)) % 24);
      let days = Math.floor(t / (1000 * 60 * 60 * 24));

      // Первая неделя нового года
      if (days > 355) {
        this.isFirstWeek = true;
        this.isLoading = false;
        return;
      }

      this.days = days;
      this.hours = hours;
      this.minutes = minutes;
      this.seconds = seconds;

      this.isLoading = false;
    },

    getEnding(number, names) {
      return getNumEnding(number, names)
    },
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  @apply bg-yellow-200 text-gray-800;
}

.new_year__button_title {
  z-index: 2;
  position: absolute;
  top: 21px;
  margin: 0;
  font-size: 24px;
  font-weight: bold;
}

.new_year__button_countdown {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  z-index: 2;
  text-align: center;

  @media screen and (max-width: 600px) {
    margin-left: 0;
  }
}

.new_year__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 13px;
  max-width: 70px;
}

.new_year__item-time {
  display: flex;
  font-weight: bold;
  font-size: 32px;
}

.new_year__item-title {
  @apply text-xs;
}

</style>