<template>
  <div>
    <Header class="mb-5" />
    <MyGifts class="mb-5" />
    <MyFriends class="mb-5" />
    <HappyNewYear />
  </div>
</template>

<script>
import {decrementHistoryDepth, setPanelHeader} from "@/lib/utils";

import Header from "../components/Home/Header";
import MyGifts from "../components/Home/MyGifts";
import MyFriends from "../components/Home/MyFriends";
import HappyNewYear from "../components/Home/HappyNewYear";

export default {
  name: "Home",
  components: {HappyNewYear, MyFriends, MyGifts, Header},
  mounted() {
    decrementHistoryDepth();
    setPanelHeader("С Новым годом!", false);
  },
};
</script>

<style scoped></style>
