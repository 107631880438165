<template>
  <div class="wrapper cursor-pointer" @click="showMyFriendPage">
    <div class="flex items-center justify-between">
      <div class="w-50 flex text-yellow-100 flex-col pr-2">
        <p class="font-medium mb-1">Мои друзья</p>
        <p class="text-xs">Узнай, что по-настоящему хотят получить твои друзья на Новый год!</p>
      </div>
      <div class="w-20 sm:h-14 flex justify-center items-center">
        <img class="block transform -rotate-12 sm-gift normal-gift" src="/img/icons/gift.png"/>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "MyFriends",
  data: function () {
    return {
      isLoading: false,
    }
  },
  methods: {
    showMyFriendPage() {
      this.$router.push({path: `/my-friends`});
    }
  }
}
</script>

<style lang="scss" scoped>
  .wrapper {
    background-color: #344d2f;
    background-image: url("/img/icons/header_bg.png");
    background-position: center;
  }

  .sm-gift {
    height: 40px;
    width: 40px;
  }

  .normal-gift {
    @screen sm {
      height: 55px;
      width: 55px;
    }
  }
</style>