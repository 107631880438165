<template>
  <div class="wrapper text-center bg-gradient-to-b from-red-500 to-red-400">
    <div v-if="!isLoading">
      <!--  Изображение 3-х подарков веером чуть разных размеров + новогодняя веточка  -->
      <img class="mx-auto" src="/img/icons/christmas-title2.png" />

      <h1 class="text-4xl mb-3 text-white stroke-title">Мои хотелки</h1>
      <p class="text-sm text-white">
        <span v-if="gifts.length === 0">
          В вашем списке ещё нет желаемых подарков. Добавить парочку?
        </span>

        <span v-if="gifts.length > 0">
          В вашем списке уже {{ gifts.length }} {{ getEnding(gifts.length, giftEndings) }}. Расскажите об этом друзьям!
        </span>
      </p>

      <div>
        <button class="button add-button" @click="showAddGiftPage">
          <span v-if="gifts.length < maxGifts">Добавить хотелку</span>
          <span v-else>Редактировать список</span>
        </button>
        <p class="text-xs mt-2 text-yellow-100">Нажмите на кнопку, чтобы добавить ещё один подарок в список желаний.</p>
      </div>

      <ul class="gift-list mt-5 mb-3 px-3 sm:px-6">
        <li class="gift-list__item" v-for="(item, i) in gifts" :key="item.id">
          <div class="flex w-full items-center overflow-hidden text-left">
            <div class="goods-list__item_number">
              {{ i + 1 }}
            </div>

            <div class="w-full pr-2 text-xs font-medium pl-10 break-words overflow-hidden">
              {{ item.title }}
            </div>
          </div>
        </li>
      </ul>

      <div v-if="gifts.length > 0" class="flex items-center justify-between mb-3 flex-col xs:flex-row">
<!--      <div class="flex items-center justify-between mb-3 flex-col xs:flex-row">-->
        <div class="w-full xs:w-1/2 xs:mr-1">
          <StoriesButton :link="getUserGiftsPageLink()"/>
        </div>
        <div class="w-full xs:w-1/2 xs:ml-1">
          <ShareButton :message="getShareMessage()" attachments="photo-209545275_457239018"/>
        </div>
      </div>

    </div>

    <Spinner v-if="isLoading"/>
  </div>
</template>

<script>
import config from "../../config";
import {store} from "../../store";
import {getNumEnding} from "../../lib/utils";
import {getGiftsByUserId, maxGifts} from "../../services/gift";

import Spinner from "../Spinner/Spinner";
import ShareButton from "../ShareButton/ShareButton";
import StoriesButton from "../StoriesButton/StoriesButton";

export default {
  name: "MyGifts",
  components: {StoriesButton, Spinner, ShareButton},
  data: function () {
    return {
      gifts: [],

      maxGifts: 7,

      isLoading: false,
      giftEndings: ["желание", "желания", "желаний"],
    }
  },
  mounted() {
    this.maxGifts = maxGifts;

    // загружаем список подарков
    this.loadGifts();
  },
  methods: {
    loadGifts() {
      this.gifts = [];

      // ищем подарки в кеше
      const storedGifts = store.state.gift.myGifts;
      if (storedGifts.length > 0) {
        this.gifts = storedGifts;
        return;
      }

      // если в кеше нет, то запрашиваем с сервера
      this.isLoading = true;
      getGiftsByUserId().then(res => {
        if (res.data.response.items !== null) {
          this.gifts = res.data.response.items;
        }

        // сохраняем список в сторе
        store.commit("gift/setMyGiftList", this.gifts);
      }).finally(() => {
        this.isLoading = false;
      })
    },

    getEnding(number, values) {
      return getNumEnding(number, values);
    },

    showAddGiftPage() {
      this.$router.push({path: `/gifts/add`});
    },

    getUserGiftsPageLink() {
      return  "https://vk.com/app" + config["app_id"] + "/#/gifts/" + store.state.user.userData.id;
    },

    getShareMessage() {
      return "Я участвую в новогоднем челлендже #ХочуНаНовыйГод 🌲 А ты? Составляй список подарков, которые хочешь получить на Новый год. Намекни своим друзьям, отправив ссылочку на список желаний 😉\n\n" +
          "А вот мой список желанных подарков: " + this.getUserGiftsPageLink() + ". Присоединяйся!";
    }
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/gift";

.stroke-title {
  font-size: 36px;
  font-weight: 900;
  color: white;
}

.add-button {
  @apply bg-yellow-200 w-full transition-all;

  &:hover {
    @apply bg-yellow-300
  }

  &:active {
    @apply bg-yellow-200;
  }

  @media (hover: none) {
    &:hover {
      @apply bg-yellow-200;
    }
  }
}

</style>