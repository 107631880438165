<template>
  <div>
    <div class="button" @click="clickShare" v-if="! isSendingSuccess && ! isSendingError">
        <div class="mr-1 justify-center"><StoryOutline /></div>
        <div class="font-medium">В историю</div>
    </div>

    <div class="message" v-if="isSendingSuccess">
      <Done/>
      <span>Опубликовано!</span>
    </div>

    <div class="message" v-if="isSendingError">
      <Cancel/>
      <span>Произошла ошибка! Попробуйте позже.</span>
    </div>
  </div>
</template>

<script>
import config from "../../config";
import bridge from '@vkontakte/vk-bridge';
import {backgroundImageBase64} from "./background";
import {stickerImageBase64} from "./sticker";

import Done from "@/components/Svg/28/Done";
import Cancel from "@/components/Svg/28/Cancel";
import StoryOutline from "../Svg/24/StoryOutline";

export default {
   name: "StoriesButton",
  components: {StoryOutline, Cancel, Done},
  props: ["link"],
  data() {
    return {
      isSendingSuccess: false,
      isSendingError: false,
    }
  },
  methods: {
    clickShare() {
      let storiesParams = {
        "background_type": "image",
        "blob": 'data:image/jpeg;base64,' + backgroundImageBase64,
        "locked": true,
        "stickers": [{
          "sticker_type": "renderable",
          "sticker": {
            "content_type": "image",
            "blob": 'data:image/jpeg;base64,' + stickerImageBase64,
            "can_delete": false,
            "transform": {
              "relation_width": 1,
              "gravity": "center_bottom"
            },
            "clickable_zones": [{
              "action_type": "link",
              "action": {
                "link": this.link,
                "action_app": {
                  "app_id": config["app_id"],
                }
              }
            }]
          }
        }]
      };

      bridge.send("VKWebAppShowStoryBox", storiesParams).then(() => {
            this.isStoriesSend = true;
          }).catch((err) => {
            console.log(err);
          }).finally(() => {
            setTimeout(() => {
              this.isSendingSuccess = false;
              this.isSendingError = false;
            }, 3000)
          });
    }
  }
}
</script>

<style lang="scss" scoped>
.message {
  @apply flex mt-3 text-sm text-center justify-center;
  color: var(--onboarding-color);

  & svg {
    @apply -mt-1 h-6;
  }
}

.button {
  @apply px-6 py-2 rounded-md cursor-pointer text-sm leading-5 outline-none flex items-center justify-center h-9 transition-all;

  color: white;
  background-color: var(--vk_btn_blue);

  &:hover {
    background-color: var(--vk_palette_blue_bright);
  }

  &:active {
    background-color: var(--vk_btn_blue);
  }

  @media (hover: none) {
    &:hover {
      background-color: var(--vk_btn_blue);
    }
  }
}
</style>