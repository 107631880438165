export default {
    namespaced: true,
    state: {
        myFriends: [],
    },
    mutations: {
        setMyFriends(state, value) {
            state.myFriends = value;
        },
    }
};
