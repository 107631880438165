export default {
    namespaced: true,
    state: {
        userData: {},

        friends: {
            token: "", // токен для досутпа к списку друзей
            users: {},// мапа с хотелками других пользователей (user_vk_id -> gifts[])
            count: 0, // количество всех друзей пользователя для пагинации
            page: 0, // номер страницы при выводе всех друзей пользователя для 1пагинации
        },

        commonUsersActive: 0,
    },
    mutations: {
        setUser(state, value) {
            state.userData = value;
        },

        setFriends(state, payload) {
            state.friends.users[payload.userId] = payload.user;
        },

        setFriendsCount(state, value) {
            state.friends.count = value;
        },

        setFriendsPage(state, value) {
            state.friends.page = value;
        },

        setFriendsToken(state, value) {
            state.friends.token = value;
        },

        setCommonUsersActive(state, value) {
            state.commonUsersActive = value;
        }
    }
};
