import {store} from "@/store";
import axios from "axios";

export const maxGifts = 7; // максимальноое количество добавляемых подарков

// Создаёт новый подарок в базе данных
export const createGift = (title) => {
    const endpoint = "/api/gift/create";
    let params = {
        'user_vk_id': parseInt(store.state.launchParams["vk_user_id"]),
        'url': store.state.launchURL,
        'title': title,
    };

    return axios.post(endpoint, params);
}

// Создаёт новый подарок в базе данных
export const deleteGift = (title) => {
    const endpoint = "/api/gift/delete";
    let params = {
        'user_vk_id': parseInt(store.state.launchParams["vk_user_id"]),
        'url': store.state.launchURL,
        'title': title,
    };

    return axios.post(endpoint, params);
}


// Загружает список подарков пользователя
export const getGiftsByUserId = () => {
    const endpoint = "/api/gift/get";
    let params = {
        'user_vk_id': parseInt(store.state.launchParams["vk_user_id"]),
        'url': store.state.launchURL,
    };

    return axios.post(endpoint, params);
}

// Загружает список подарков для списка пользователей
export const getGiftsByUserIds = (ids) => {
    const endpoint = "/api/gift/get-list";
    let params = {
        'user_vk_id': parseInt(store.state.launchParams["vk_user_id"]),
        'url': store.state.launchURL,
        'user_vk_ids': ids,
    };

    return axios.post(endpoint, params);
}

