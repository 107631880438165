<template>
  <Transition name="fade">
    <div
        v-if="showing"
        class="fixed inset-0 w-full h-screen flex items-center justify-center bg-smoke-800 z-50"
        @click.self="closeIfShown"
        :class="customCSS.background"
    >
      <div
          class="modal-wrapper"
          :class="customCSS.modal"
      >
        <button
            v-if="showClose"
            aria-label="close"
            class="absolute top-0 right-0 text-xl text-gray-500 my-2 mx-4"
            :class="customCSS.close"
            @click.prevent="close"
        >
          <Cancel classes="w-4 cursor-pointer"/>
        </button>
        <div class="overflow-auto max-h-screen w-full">
          <slot />
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import Cancel from "../Svg/28/Cancel";

export default {
  name: "Modal",
  components: {Cancel},
  props: {
    showing: {
      required: true,
      type: Boolean
    },
    showClose: {
      type: Boolean,
      default: true
    },
    backgroundClose: {
      type: Boolean,
      default: true,
    },
    css: {
      type: Object,
      required: false
    }
  },
  computed: {
    customCSS() {
      return {...{
          background: '',
          modal: '',
          close: ''
        }, ...this.css}
    }
  },
  watch: {
    showing(value) {
      if (value) {
        return document.querySelector("body").classList.add("overflow-hidden");
      }
      return document.querySelector("body").classList.remove("overflow-hidden");
    }
  },
  methods: {
    close() {
      document.querySelector("body").classList.remove("overflow-hidden");
      this.$emit("close");
    },
    closeIfShown() {
      if (this.showClose && this.backgroundClose) {
        this.close();
      }
    }
  },
  mounted: function() {
    if (this.showClose) {
      document.addEventListener("keydown", e => {
        if (e.key === "Escape") {
          this.close();
        }
      });
    }

    window.addEventListener('popstate', () => {
      this.close();
    })
  }
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.modal-wrapper {
  @apply relative max-h-screen w-10/12 max-w-2xl shadow-lg rounded-md p-4 flex;

  background-color: var(--white-wrapper-background);
  color: var(--default-text-color);
}
</style>