<template>
  <div class="wrapper">
    <div class="text-center text-yellow-100">
      <h1 class="text-xl sm:text-2xl font-bold">#ХочуНаНовыйГод</h1>
      <p class="text-sm italic mt-1">

        Новогодний челлендж для друзей
        <img src="/img/emoji/gift.png" class="inline -mt-1 h-5" />
      </p>
    </div>

    <div class="text-yellow-100 mt-5">
      <div class="w-full flex items-center mb-2">
        <div class="w-10 mr-3">
          <div class="border rounded-full w-8 h-8 flex items-center justify-center">1</div>
        </div>
        <div class="w-full">
          <p class="font-medium">Придумывай</p>
          <p class="text-xs">Придумай и запиши в свой список те подарки, которые ты хочешь получить на Новый год.</p>
        </div>
      </div>

      <div class="w-full flex items-center mb-2">
        <div class="w-10 mr-3">
          <div class="border rounded-full w-8 h-8 flex items-center justify-center">2</div>
        </div>
        <div class="w-full">
          <p class="font-medium">Рассказывай</p>
          <p class="text-xs">Опубликуй свой список подарков в «Истории» и на своей стене во ВКонтакте, чтобы друзья
            увидели, что ты хочешь получить на Новый год.</p>
        </div>
      </div>

      <div class="w-full flex items-center mb-2">
        <div class="w-10 mr-3">
          <div class="border rounded-full w-8 h-8 flex items-center justify-center">3</div>
        </div>
        <div class="w-full">
          <p class="font-medium">Отмечай</p>
          <p class="text-xs">Встреть Новый год вместе с друзьями, обмениваясь желанными подарками!</p>
        </div>
      </div>
    </div>

    <div class="text-center text-yellow-100 mt-5">
      <p class="text-xs" v-if="!isLoading">В челлендже уже <span class="italic">{{ usersAmount }}</span> {{ getEnding(usersAmount, ['участник','участника','участников']) }}!</p>
      <Spinner v-if="isLoading" />
    </div>

  </div>
</template>

<script>
import bridge from "@vkontakte/vk-bridge";
import {store} from "../../store";
import {getUsersAmount} from "../../services/user";
import {getNumEnding} from "../../lib/utils";

import Spinner from "../Spinner/Spinner";

export default {
  name: "Header",
  components: {Spinner},
  data() {
    return {
      user: {},
      usersAmount: 0,
      isLoading: false,
    };
  },
  mounted() {
    // Загрузка общего количества участников
    this.loadUsersAmount();

    this.$nextTick(() => {
      this.loadUser();
    });
  },
  methods: {
    getUserName() {
      let firstName = "";
      let lastName = "";

      if (this.user !== undefined) {
        if (this.user.first_name !== undefined) {
          firstName = this.user.first_name.trim();
        }

        if (this.user.last_name !== undefined) {
          lastName = this.user.last_name.trim();
        }
      }

      return firstName + " " + lastName;
    },

    loadUser() {
      // Проверяем пользователя в сторе
      const storedUser = store.state.user.userData;
      if (Object.keys(storedUser).length > 0) {
        this.user = storedUser;
        return;
      }

      // Если пользователя в сторе нет, то делаем запрос в bridge
      this.isLoading = true;
      bridge.send('VKWebAppGetUserInfo').then(res => {
        this.user = res;

        // Сохранение данных о пользователе в кеше
        store.commit("user/setUser", res);
      }).catch(err => {
        console.log("VKWebAppGetUserInfo: ", err);
      }).finally(() => {
        this.isLoading = false;
      });
    },

    loadUsersAmount() {
      // проверяем наличие общего количества пользователей в кеше
      const storedAmount = store.state.user.commonUsersActive;
      if (storedAmount > 0) {
        this.usersAmount = storedAmount;
        return;
      }

      this.isLoading = true;
      getUsersAmount().then(res => {
        this.usersAmount = res.data.response.count;
        // сохраняем данные в кеше
        store.commit("user/setCommonUsersActive", this.usersAmount);
      }).finally(() => {
        this.isLoading = false;
      })
    },

    getEnding(number, values) {
      return getNumEnding(number, values);
    },
  }
}
</script>

<style lang="scss" scoped>
  .wrapper {
    background-color: #344d2f;
    background-image: url("/img/icons/header_bg.png");
  }
</style>