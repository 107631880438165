import {store} from "@/store";
import axios from "axios";

// Получает данные об польователе и его подарках
export const getUserById = (userId) => {
    const endpoint = "/api/user/get-by-id";
    let params = {
        'user_vk_id': parseInt(store.state.launchParams["vk_user_id"]),
        'url': store.state.launchURL,
        'user': parseInt(userId),
    };

    return axios.post(endpoint, params);
}


// Получает данные о количестве пользователей
export const getUsersAmount = () => {
    const endpoint = "/api/user/get-users-amount";
    let params = {
        'user_vk_id': parseInt(store.state.launchParams["vk_user_id"]),
        'url': store.state.launchURL,
    };

    return axios.post(endpoint, params);
}

