<template>
  <div>
    <div class="wrapper error-wrapper" v-if="!isLoading && errorNotFound">
      <div class="text-center mx-auto">
        <p class="text-sm mb-3">Ой, пользователь не найден.</p>
        <button class="button back-button mt-2 mx-auto" @click="goToHome()">
          На главную
        </button>
      </div>
    </div>

    <div class="wrapper text-center bg-gradient-to-b from-red-500 to-red-400 mt-5" v-if="!errorNotFound">
      <div v-if="!isLoading">
        <!--  Изображение 3-х подарков веером чуть разных размеров + новогодняя веточка  -->
        <img class="mx-auto" src="/img/icons/christmas-title2.png"/>

        <h1 class="text-4xl mb-3 text-white overflow-hidden stroke-title" v-html="getUserName()"></h1>
        <div v-if="user.gifts && user.gifts.length > 0">
          <p class="text-xs mt-2 text-yellow-100">
            Это список самых желанных подарков, которые стоит вручить на Новый год!
            <img src="/img/emoji/gift.png" class="inline -mt-1 ml-1 h-5"/>
          </p>
        </div>

        <ul class="gift-list mt-5 mb-3 px-3 sm:px-6" v-if="user.gifts && user.gifts.length > 0">
          <li class="gift-list__item" v-for="(item, i) in user.gifts" :key="item.id">
            <div class="flex w-full items-center overflow-hidden text-left">
              <div class="goods-list__item_number">
                {{ i + 1 }}
              </div>

              <div class="w-full pr-2 text-xs font-medium pl-10 break-words overflow-hidden">
                {{ item.title }}
              </div>
            </div>
          </li>
        </ul>
        <p class="text-center text-white text-sm" v-else>Ой, список желанных подарков пуст.</p>
      </div>
      <Spinner v-if="isLoading"/>
    </div>
  </div>
</template>

<script>
import {store} from "../store";
import {decrementHistoryDepth, setPanelHeader, toTopPage} from "../lib/utils";

import Spinner from "../components/Spinner/Spinner";
import {getUserById} from "../services/user";

export default {
  name: "ShowGifts",
  components: {Spinner},
  data: function () {
    return {
      user: {},

      isLoading: false,
      errorNotFound: false,
    }
  },
  mounted() {
    decrementHistoryDepth();
    setPanelHeader("Назад", true);
    toTopPage();

    // Инициализация
    this.user = {};
    this.user.gifts = [];

    // Загрузка списка подарков из кеша или сервера
    this.loadGifts();
  },
  methods: {
    loadGifts() {
      this.isLoading = true;

      const userId = parseInt(this.$route.params.id);

      console.log(userId);

      if (userId === 0) {
        this.isLoading = false;
        this.errorNotFound = true;
        return;
      }

      // ищем друзей в кеше
      const storedUsers = store.state.user.friends.users;
      console.log("cache", Object.keys(storedUsers).length)

      if (Object.keys(storedUsers).length > 0) {
        this.user = storedUsers[userId];
        this.isLoading = false;
        return;
      }

      // отправляем запрос на сервер для получения информации данных об пользователе
      getUserById(userId).then(res => {
        const user = res.data.response.user;
        const gifts = res.data.response.gifts;

        console.log(user, gifts);

        if (Object.keys(user).length > 0) {
          this.user = user;
          if (this.user.gifts === null) {
            this.user.gifts = [];
          }
        } else {
          this.user = {};
          this.user.gifts = [];
          this.errorNotFound = true;
        }

        if (gifts !== null && gifts.length > 0) {
          this.user.gifts = gifts;
        } else {
          this.user.gifts = [];
        }
      }).catch(err => {
        console.log(err)
        this.errorNotFound = true;
      }).finally(() => {
        this.isLoading = false;
      })
    },

    getUserId() {
      return store.state.launchParams["vk_user_id"];
    },

    getUserName() {
      let firstName = "";
      let lastName = "";

      if (this.user !== undefined) {
        if (this.user.first_name !== undefined) {
          firstName = this.user.first_name.trim();
        }

        if (this.user.last_name !== undefined) {
          lastName = this.user.last_name.trim();
        }
      }

      return firstName + " " + lastName;
    },

    goToHome() {
      this.$router.replace({path: `/`});
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/gift";

.stroke-title {
  font-size: 36px;
  font-weight: 900;
  color: white;
}

.error-wrapper {
  @apply rounded-md py-2 list-none flex items-center overflow-hidden;

  background-color: var(--white-wrapper-background);
  color: var(--default-text-color);
}

.back-button {
  @apply transition-all;
  color: white;
  background-color: var(--vk_btn_blue);

  &:hover {
    background-color: var(--vk_palette_blue_bright);
  }

  &:active {
    background-color: var(--vk_btn_blue);
  }

  @media (hover: none) {
    &:hover {
      background-color: var(--vk_btn_blue);
    }
  }
}
</style>