export default {
    namespaced: true,
    state: {
        myGifts: [], // список хотелок пользователя
    },
    mutations: {
        setMyGiftList(state, value) {
            state.myGifts = value;
        },
    }
};
