<template>
  <div>

    <!-- Пояснения  -->
    <Modal
        :showing="showModal"
        @close="showModal = false"
        :showClose="true"
        :backgroundClose="true"
    >
      <p class="font-bold mb-3">Пригласи друга</p>

      <p class="mb-3 text-sm">Скопируй сообщение и отправь другу, чтобы вместе принять участие в новогоднем челлендже.</p>

      <p class="w-full text-xs p-2 border rounded-md" v-html="inviteMessage"></p>

      <div class="flex justify-center">
        <span class="button shrink" @click="openFriedLink()">Скопировать и отправить сообщение</span>
      </div>
    </Modal>

    <div class="wrapper">
      <div v-if="token !== null">
        <div v-if="friends.length > 0">
          <ul class="friends">
            <li :class="{'cursor-pointer' : item.gifts.length > 0 }" v-for="item in friends" :key="item.id"
                @click="showGiftsMyFriend(item)">
              <div class="friends__item" v-if="!item.deactivated">
                <img :src="getUserAvatar(item)"
                     class="rounded-full mr-5 h-16 w-16 border-none"/>

                <div class="overflow-hidden flex flex-col justify-center py-1">
                  <div class="w-full">
                    <p class="text-sm font-medium whitespace-nowrap">{{ item.first_name }} {{ item.last_name }}</p>
                  </div>

                  <div class="w-full text-xs" v-if="item.gifts.length > 0">
                    🎁 В списке желаний {{ item.gifts.length }} {{ getEnding(item.gifts.length, giftEndings) }}
                  </div>

                  <div class="w-full flex-col sm:flex sm:flex-row text-xs" v-if="item.gifts.length === 0">
                    <!-- desktop -->
                    <!--                  <div class="hidden sm:block sm:w-8/12" :class="{'sm:w-full': isIPhone()}">-->
                    <div class="hidden sm:block sm:w-8/12">
                      <p>Пригласи друга, чтобы он рассказал о своих хотелках на Новый год!</p>
                    </div>

                    <!-- mobile -->
                    <div class="flex w-full sm:hidden">
                      <p>Пригласи друга для участия в челлендже подарков!</p>
                    </div>

                    <!--                  <div class="mt-2 sm:m-0 sm:w-4/12 sm:text-right" v-if="!isIPhone()">-->
                    <div class="mt-2 sm:m-0 sm:w-4/12 sm:text-right">
                      <span @click="showFriendsDialog(item)" class="invite-button">
                        Пригласить
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>

          <!-- Кнопка на подгрузку остальных друзей -->
          <div v-if="(countAllFriends > friends.length || isAllFriendsLoaded) && !isLoading">
            <!--        <div v-if="isAllFriendsLoaded === false && !isLoading">-->
            <span class="button next-load-button mt-2 mx-auto" @click="loadFriendsWithToken">
            Показать ещё
          </span>
          </div>
        </div>

        <p v-if="!isLoading && friends.length === 0" class="text-sm text-center">Список друзей пуст.</p>
      </div>

      <Spinner v-if="isLoading"/>

      <!-- Плашка при отсутствии прав на список друзей -->
      <div v-if="!isLoading && token === null">
        <p class="text-sm text-center mb-3">
          Ой, у меня нет разрешения, я не могу посмотреть Ваших друзей ¯\_(ツ)_/¯
        </p>

        <button class="button permission-button mt-2 mx-auto" @click="loadFriendsWithToken">
          Разрешить доступ
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import bridge from "@vkontakte/vk-bridge";
import config from "../config";
import {store} from "../store";
import {getGiftsByUserIds} from "../services/gift";
import {decrementHistoryDepth, getNumEnding, isIPhone, setPanelHeader, toTopPage} from "../lib/utils";

import Spinner from "../components/Spinner/Spinner";
import Modal from "../components/Modal/Modal";

export default {
  name: "MyFriends",
  components: {Modal, Spinner},
  data: function () {
    return {
      countAllFriends: 0,
      friends: [],


      showedFriend: {},
      inviteMessage: "",
      showModal: false,

      neededScope: "friends",
      scope: [],
      token: null,

      pagination: {
        elemPerPage: 50,
        page: 0,
      },

      isLoading: false,
      isAllFriendsLoaded: false,
      giftEndings: ["подарок", "подарка", "подарков"],
    }
  },
  mounted() {
    decrementHistoryDepth();
    setPanelHeader("Мои друзья", true);
    toTopPage();

    this.token = store.state.user.friends.token;
    // загрузка данных из кеша для пагинации
    this.countAllFriends = store.state.user.friends.count;
    this.pagination.page = store.state.user.friends.page;

    // console.log("count", this.countAllFriends);
    // console.log("page", this.pagination.page);

    if (this.friends.length !== 0 && this.friends.length >= this.countAllFriends) {
      // console.log("все пользователи в кеше, запрос не делаем");
      return;
    }

    // ищем друзей в кеше
    const storedUsers = store.state.user.friends.users;
    const userKeys = Object.keys(storedUsers);
    // console.log("cache", userKeys.length)

    if (userKeys.length > 0) {
      for (let i = 0; i < userKeys.length; i++) {
        this.friends.push(storedUsers[userKeys[i]]);
      }

      // Сортировка друзей по количеству подарков
      this.friends.sort(this.sortFriends);

      return;
    }

    // Загрузка друзей с предварительным получением токена
    this.loadFriendsWithToken();
  },
  methods: {
    loadFriendsWithToken() {
      this.isLoading = true;
      bridge.send("VKWebAppGetAuthToken", {"app_id": config["app_id"], "scope": this.neededScope}).then((res) => {
        if (res.scope.indexOf(this.neededScope) === -1) {
          this.token = null;
          this.isLoading = false;
          return;
        }

        this.scope = res.scope;
        this.token = res.access_token;
        store.commit("user/setFriendsToken", this.token);

        // если кеш пустой, то загружать с сервера
        this.loadFriends();
      }).catch(err => {
        console.log(err);
        this.token = null;
        this.isLoading = false;
      });
    },

    loadFriends() {
      const offset = this.pagination.page * this.pagination.elemPerPage;

      bridge.send("VKWebAppCallAPIMethod", {
            "method": "friends.get",
            "params": {
              "user_id": this.getUserId(),
              "count": this.pagination.elemPerPage,
              "offset": offset,
              "order": "hints",
              "fields": "photo_100, bdate",
              "v": "5.103",
              "access_token": this.token
            }
          }
      ).then((res) => {
        this.countAllFriends = res.response.count;
        // сохраняем количество друзей в кеше
        store.commit("user/setFriendsCount", this.countAllFriends);

        if (res.response.items !== null) {
          // все друзья загружены
          if (this.friends.length > 0 && res.response.items.length === 0) {
            this.isAllFriendsLoaded = true;
            this.isLoading = false;
            return;
          }

          let userIds = [];
          let users = [];
          for (let i = 0; i < res.response.items.length; i++) {
            users.push(res.response.items[i]);
            userIds.push(res.response.items[i].id);
          }

          // запрашиваем листы подарков для пользователей
          getGiftsByUserIds(userIds).then(res => {
            for (let i = 0; i < users.length; i++) {
              let user = users[i];

              let gifts = res.data.response.items[user.id];
              if (gifts === null) {
                user.gifts = [];
              } else {
                user.gifts = gifts;
              }

              this.friends.push(user);

              // сохраняем пользователей в кеше
              store.commit("user/setFriends", {
                "userId": user.id,
                "user": user,
              });
            }

            this.pagination.page++;
            // сохраняем страницу поиска в кеше
            store.commit("user/setFriendsPage", this.pagination.page);

            // Сортировка друзей по количеству подарков
            this.friends.sort(this.sortFriends);

            this.isLoading = false;
          }).catch(err => {
            console.log(err);
            this.friends = [];
          });
        }
      });
    },

    showGiftsMyFriend(user) {
      if (!user.gifts || user.gifts.length === 0) {
        return;
      }

      this.$router.push({path: `/gifts/${user.id}`});
    },

    showFriendsDialog(user) {
      this.showModal = true;
      this.showedFriend = user;

      this.inviteMessage = this.generateWelcomeMessage();
    },

    openFriedLink(){
      bridge.send("VKWebAppCopyText", {"text": this.inviteMessage}).then(() => {
        const url = `https://vk.com/write${this.showedFriend.id}`;
        window.open(url);
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.showModal = false;
        this.showedFriend = null;
        this.inviteMessage = "";
      });
    },

    getEnding(number, values) {
      return getNumEnding(number, values);
    },

    getUserId() {
      return store.state.launchParams["vk_user_id"];
    },

    sortFriends(a, b) {
      if (a.gifts.length < b.gifts.length) return 1; // если первое значение больше второго
      if (a.gifts.length === b.gifts.length) return 0; // если равны
      if (a.gifts.length > b.gifts.length) return -1; // если первое значение меньше второго
    },

    isIPhone() {
      return isIPhone();
    },

    generateWelcomeMessage() {
      let msg = "Привет, " + this.showedFriend.first_name + "! Приглашаю тебя принять участие в новогоднем челлендже! Составляй список желанных подарков на Новый год и делись списком со своими друзьями! 🎉 \n\n" +
          "Заходи скорее: vk.com/app" + config["app_id"] + " 🎄";

      return msg;
    },

    getUserAvatar(user) {
      if (user.photo_100 === "https://vk.com/images/camera_100.png" || user.photo_100.length === 0) {
        return "/img/avatar/no_avatar.png";
      }

      return user.photo_100;
    }
  },
  // TODO отключить восстановление позиции скролла
  // beforeRouteLeave(to, from, next) {
  //   if (this.showModal === true) {
  //     this.showModal = false;
  //     next(false);
  //   } else {
  //     next();
  //   }
  // },
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/buttons";

.friends__item {
  @apply rounded-md py-2 px-2 list-none flex items-center;
}

.friends__item:not(:first-child) {
  //@apply mt-2;
}

.button {
  @apply transition-all;
  color: white;
  background-color: var(--vk_btn_blue);

  &:hover {
    background-color: var(--vk_palette_blue_bright);
  }

  &:active {
    background-color: var(--vk_btn_blue);
  }

  @media (hover: none) {
    &:hover {
      background-color: var(--vk_btn_blue);
    }
  }
}

.invite-button {
  @apply cursor-pointer rounded-full text-xs text-gray-800 font-medium py-1 px-4 bg-yellow-200 transition-all;

  &:hover {
    @apply bg-yellow-300
  }

  &:active {
    @apply bg-yellow-200;
  }

  @media (hover: none) {
    &:hover {
      @apply bg-yellow-200;
    }
  }
}

//.button {
//  @apply block text-center cursor-pointer rounded-md px-6 py-2 text-gray-700 font-medium text-sm mt-3 shadow-md outline-none;
//}

.permission-button {
  color: white !important;
  background-color: var(--vk_btn_blue);

  &:hover {
    background-color: var(--vk_palette_blue_bright);
  }

  &:active {
    background-color: var(--vk_btn_blue);
  }

  @media (hover: none) {
    &:hover {
      background-color: var(--vk_btn_blue);
    }
  }
}

.next-load-button {
  color: var(--vk_text_blue);
  background-color: var(--vk_secondary_button);

  &:hover {
    background-color: var(--vk_secondary_button_hover);
  }

  &:active {
    background-color: var(--vk_secondary_button);
  }

  @media (hover: none) {
    &:hover {
      background-color: var(--vk_secondary_button);
    }
  }
}
</style>