<template>
  <div>
    <div class="button" @click="clickShare" v-if="! isSendingSuccess && ! isSendingError">
      <div class="mr-1 justify-center"><Share /></div>
      <div class="font-medium">На стену</div>
    </div>

    <div class="message" v-if="isSendingSuccess">
      <Done/>
      <span>Опубликовано!</span>
    </div>

    <div class="message" v-if="isSendingError">
      <Cancel/>
      <span>Произошла ошибка! Попробуйте позже.</span>
    </div>
  </div>
</template>

<script>
import bridge from '@vkontakte/vk-bridge';
import Done from "@/components/Svg/28/Done";
import Cancel from "@/components/Svg/28/Cancel";
import Share from "@/components/Svg/24/Share";

export default {
  name: "ShareButton",
  components: {Share, Cancel, Done},
  props: ["message", "attachments"],
  data() {
    return {
      isSendingSuccess: false,
      isSendingError: false,
    }
  },
  methods: {
    clickShare() {
      if (this.message.length === 0) { return; }

      bridge.send("VKWebAppShowWallPostBox", {
        "message": this.message,
        "attachments": this.attachments,
      }).then(() => {
        this.isSendingSuccess = true;
      }).catch(err => {
        console.log(err);
      }).finally(() => {
        setTimeout(() => {
          this.isSendingSuccess = false;
          this.isSendingError = false;
        }, 3000)
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.message {
  @apply flex mt-3 text-sm text-center justify-center;
  color: var(--onboarding-color);

  & svg {
    @apply -mt-1 h-6;
  }
}

.button {
  @apply px-6 py-2 rounded-md cursor-pointer text-sm leading-5 outline-none flex items-center justify-center h-9 transition-all;

  color: white;
  background-color: var(--vk_btn_blue);

  &:hover {
    background-color: var(--vk_palette_blue_bright);
  }

  &:active {
    background-color: var(--vk_btn_blue);
  }

  @media (hover: none) {
    &:hover {
      background-color: var(--vk_btn_blue);
    }
  }
}
</style>